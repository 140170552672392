/** 求购表单 */
<template>
  <el-form :model="form" :rules="rulesForm" ref="form" label-width="100px">
    <h2 class="mb-30">求购信息</h2>
    <el-form-item prop="name" label="求购知产" required>
      <el-select v-model="form.name" filterable placeholder="请选择知产类型">
        <el-option label="专利" value="Patent"> </el-option>
        <el-option label="商标" value="Trademark"> </el-option>
      </el-select>
    </el-form-item>

    <el-form-item v-show="form.name === 'Patent'" prop="type" label="专利类别" required>
      <el-select v-model="form.type" filterable placeholder="请选择专利类别">
        <el-option label="发明专利" value="发明专利"> </el-option>
        <el-option label="实用新型" value="实用新型"> </el-option>
        <el-option label="外观设计" value="外观设计"> </el-option>
      </el-select>
    </el-form-item>

    <el-form-item prop="group" label="求购类别">
      <el-select v-model="form.group" filterable placeholder="请选择求购类别">
        <el-option v-for="item in $data.industryTypes[form.name]" :key="item.name" :label="item.name" :value="item.name">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item prop="details" label="需求详情">
      <el-input type="textarea" v-model="form.details" placeholder="需求详情"></el-input>
    </el-form-item>

    <el-form-item prop="price" label="预算">
      <el-input v-model.number="form.price" placeholder="不填写价格，默认显示面议"></el-input>
    </el-form-item>

    <el-form-item prop="expired_at" label="截止有效期" required>
      <el-date-picker type="date" v-model="form.expired_at" :picker-options="pickerOptions" value-format="yyyy-MM-dd"
        placeholder="选择日期">
      </el-date-picker>
    </el-form-item>

    <h2 class="mb-30">联系人</h2>

    <el-form-item prop="user_name" label="联系人" :rules="$rules.common.name">
      <el-input v-model="form.user_name" placeholder="请输入用户名称"> </el-input>
    </el-form-item>

    <el-form-item prop="user_mobile" label="手机号" :rules="$rules.common.phone">
      <el-input v-model="form.user_mobile" placeholder="请输入手机号码"></el-input>
    </el-form-item>

    <el-form-item class="form-submit">
      <el-button plain @click="goBack">
        返回
      </el-button>
      <el-button class="theme-back" type="primary" :loading="loading" @click="submitForm"> {{ isEdit ? '修改' : '发布'
      }}</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import * as TradingApi from '@/api/trading'
export default {
  name: 'MyTradingBuyForm',
  props: ['id'],
  data () {
    var checkPrice = (rule, value, callback) => {
      const patter = new RegExp(/^[1-9]\d*$/)
      if (value && (!Number.isInteger(value) || !patter.test(value))) {
        return callback(new Error('请输入正整数'));
      }
      callback()
    };
    return {
      loading: false,
      form: {
        name: 'Patent',
        type: '发明专利',
        group: '',
        details: '',
        price: '',
        expired_at: '',
        user_name: '',
        user_mobile: '',
      },
      // 行业类型
      industryTypes: {},
      // 日期选择器限制只能选今天以及今天之后的日期
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now();//如果没有后面的-8.64e7就是不可以选择今天的 
        }
      },
      rulesForm: {
        details: { required: true, message: '请输入需求详情', trigger: 'blur' },
        group: { required: true, message: '请选择求购类别', trigger: 'blur' },
        price: { validator: checkPrice, trigger: 'blur' },
        expired_at: { required: true, message: '请选择截止日期', trigger: 'blur' }
      }
    }
  },
  computed: {
    isEdit () {
      return this.$route.query.id ? true : false
    },
  },
  mounted () {
    this.getTradingtype()
    // 重置form表单
    this.$refs.form.resetFields()
    if (this.isEdit) {
      this.fetchData()
    }
  },
  methods: {
    async getTradingtype () {
      try {
        let { data } = await TradingApi.getTradingtype()
        this.industryTypes = data
      } catch (error) { }
    },
    async fetchData () {
      try {
        const { data } = await TradingApi.fetchAsktobuy(this.$route.query.id)
        data['price'] = data['price'] == 0 ? '' : data['price']
        this.form = data
      } catch (error) {
        return false
      }
    },
    // 提交
    submitForm () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true
          let queryForm = { ...this.form }
          for (const key in queryForm) {
            if (key == 'price' && !queryForm['price']) {
              queryForm[key] = 0
            }
            if (queryForm.name == 'Trademark') {
              queryForm['type'] = '商标'
            }
          }
          try {
            let { code, msg } = this.isEdit ? await TradingApi.editAsktobuy(queryForm) : await TradingApi.addAsktobuy(queryForm)
            if (code == 200) {
              if (!this.isEdit) {
                this.$store.dispatch('setIsTradingListReset', true)
              }
              this.goBack()
            } else {
              this.$message.error(msg);
            }
          } catch (error) { }
          this.loading = false
        } else {
          return false;
        }
      });
    },
    goBack () {
      this.$router.push('/my/trading/buy')
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-form-item {
  &:last-child {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .el-input,
  .el-select,
  .el-date-editor {
    width: 100%;
  }

  .el-date-editor {
    .el-input {
      width: 100% !important;

      .el-input__inner {
        padding-left: 15px;
      }

      .el-input__prefix {
        display: none;
      }
    }
  }

}
</style>